const breaks = {
    DESKTOP: 992,
    TABLET: 768,
    TABLET_LANDSCAPE: 1024,
    PHONE: 576,
    container: 1400,
};

export const themeLight = {
    radio: '10px',
    fg: "palevioletred",
    red: "palevioletred",
    white: "#fff",
    prodItemBg: "#fff",
    primary: "#112c55",
    whiteAndPrimary: "#fff",
    primaryAndWhite: "#112c55",
    primaryDarkMode: "#112c55",
    primaryHover: "#1b3c6d",
    primaryDark: "#363636",
    primaryGradient: "rgba(17, 44, 85, 0.97)",
    borderColor: "#e3e3e3",
    textPlaceholder: "#979797",
    textDefault: "#191919",
    complementary: "#245FB7",
    black: "#191919",
    textDefaultGrayed: "#686868",
    gray: "#7a7a7a",
    grayLight: "#f3f5f6",
    grayLightAndPrimary: "#f8f8f8",
    graySoft: "#eeeeee",
    blueLight: "rgba(240, 245, 254, 1)",
    blueLightAlpha: "rgba(240, 245, 254, 0.95)",
    main: "#BCD2EE",
    link: "#558fe8",
    success: "#257942",
    alert: "#947600",
    error: "#cc0f35",
    inputBg: "#fff",
    inputBorder: "#e3e3e3",
    btnWhiteBorder: "#e3e3e3",
    btnWhiteBg: "white",
    btnWhiteBgHover: "#f9f9f9",
    btnPrimaryBorder: "transparent",
    btnDefaultBorder: "#e3e3e3",
    btnDefaultBg: "#fff",
    btnDefaultBgHover: "#595959",
    darkMode: false,
    breaks,
    isStandalone:
        typeof window !== "undefined" && window.env && window.env.isStandalone
            ? window.env.isStandalone
            : false,
};

export const themeDark = {
    radio: '10px',
    fg: "palevioletred",
    red: "palevioletred",
    white: "#181d26",
    prodItemBg: "#343842",
    primary: "#112c55",
    whiteAndPrimary: "#112c55",
    primaryAndWhite: "#4d4d4d",
    primaryDarkMode: "#fff",
    primaryHover: "#1b3c6d",
    primaryDark: "#c9c9c9",
    borderColor: "#252c39",
    primaryGradient: "rgba(125, 144, 174, 0.97)",
    textPlaceholder: "#979797",
    textDefault: "#e6e6e6",
    complementary: "#245FB7",
    black: "#e6e6e6",
    textDefaultGrayed: "#686868",
    gray: "#9b9b9b",
    grayLight: "#14161a",
    grayLightAndPrimary: "#112c55",
    graySoft: "#2e323a",
    blueLight: "rgba(23, 34, 53, 1)",
    blueLightAlpha: "rgba(23, 34, 53, 0.95)",
    main: "#BCD2EE",
    link: "#558fe8",
    success: "#257942",
    alert: "#947600",
    error: "#cc0f35",
    inputBg: "#262626",
    inputBorder: "#475062",
    btnWhiteBorder: "#646464",
    btnWhiteBg: "#4d4d4d",
    btnWhiteBgHover: "#595959",
    btnDefaultBorder: "#646464",
    btnDefaultBg: "#4d4d4d",
    btnDefaultBgHover: "#595959",
    btnPrimaryBorder: "#425777",
    darkMode: true,
    breaks,
    isStandalone:
        typeof window !== "undefined" && window.env && window.env.isStandalone
            ? window.env.isStandalone
            : false,
};

// export default theme;
