import React from "react";
import styled from "styled-components";

// import inEU from "@segment/in-eu";
import InternalLink from "../../InternalLink";
import { Columns, Column } from "../../../sdk/Layout";
import { Button } from "../../../sdk/Button";
import { getCookie, setCookie } from "../../../utils";
import { useTranslation } from "../../../../lang";
const Diaglog = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
`;

export const Cookies = styled.div`
    padding: 20px;
    background-color: #112c55;
    color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    display: ${(props) => !props.open && "none"};
    h4 {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 22px;
    }
    a.center {
        color: white;
        font-size: 11px;
        border-bottom-color: #464646;
        text-transform: underline;
        text-align: center;
        display: block;
        margin: 10px auto;
    }

    ${Columns} {
        align-items: center;
    }
    @media (max-width: 768px) {
        padding: 7px;
        p {
            font-size: 10px;
            line-height: 12px;
        }
        & ${Column}:last-child {
            padding-top: 0;
        }
        & ${Button} {
            font-size: 12px;
            margin: 10px auto;
        }
    }
`;

export function setCookiConsent() {
    setCookie("ck-consent", "yes", 300);
}
export function initializeAnalytics() {
    if (
        typeof window !== "undefined" &&
        typeof window.analytics !== "undefined" &&
        window.analytics.load
    )
        window.analytics.load(
            window.TrackingCode
                ? window.TrackingCode
                : "m5SUKDuhKxivZTYHH8ZvWQ69iRVpmCzh"
        );
}

export default function ({ bypasConsent }) {
    const [_t] = useTranslation();
    const [hasConcent, setHasConcent] = React.useState(() => {
        if (bypasConsent) {
            if (typeof window !== "undefined") {
                setCookiConsent();
            }
            return true;
        }
        if (typeof window === "undefined") return true;
        return getCookie("ck-consent") == "yes";
    });

    const handleAcceptConnsent = () => {
        setCookiConsent();
        initializeAnalytics();
        setHasConcent(true);
    };

    if (hasConcent) return null;
    const bannerContent = (
        <Cookies open={true}>
            <Columns>
                <Column>
                    <h4>{_t("cookiePolicy")}</h4>
                    <p>
                        {_t("cookiePolicy2")}
                        <InternalLink to="/pages/cookies" pimary small>
                            {" "}
                            {_t("cookiePolicy")}
                        </InternalLink>
                    </p>
                </Column>
                <Column isNarrow>
                    <Button onClick={handleAcceptConnsent}>
                        {_t("acceptGeo")} Cookies
                    </Button>
                    <InternalLink
                        className="center"
                        to="/cookie-manager"
                        pimary
                        small
                    >
                        Cookie {_t("settings")}
                    </InternalLink>
                </Column>
            </Columns>
        </Cookies>
    );

    return <Diaglog>{bannerContent}</Diaglog>;
}
