import styled from "styled-components";

export const Button = styled.button`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    box-shadow: none;
    height: 2.5em;
    line-height: 1.5;
    position: relative;
    display: flex;
    vertical-align: top;
    border: solid 1px ${(props) => props.theme.btnDefaultBorder};
    font-size: 16px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.btnDefaultBg};
    color: ${(props) => props.theme.primaryDark};
    ${(props) => (props.isFullWidth ? "width: 100%;" : "")}

    :active,
	:focus {
        outline: none;
    }

    :hover {
        border-color: ${(props) => props.theme.gray};
        color: ${(props) => props.theme.primaryDark};
    }
    /*
    :focus {
        border-color: #3273dc;
        color: #363636;
    }*/

    :active,
    &.is-active {
        border-color: ${(props) => props.theme.primaryDark};
        z-index: 2;
    }
    ${(props) => {
        if (props.primary) {
            return `
				border-color: ${props.theme.btnPrimaryBorder};
				background-color: ${props.theme.primary};
				color: #fff;
				:hover {
					background-color: ${props.theme.primaryHover};
					color: #fff;
					border-color: ${props.theme.btnPrimaryBorder};
				}
			`;
        }
    }}
    ${(props) => {
        if (props.white) {
            return `
				background-color: ${props.theme.btnWhiteBg};
				border-color: ${props.theme.btnWhiteBorder};
				color: ${props.theme.primaryDark};
				:hover {
					background-color: ${props.theme.btnWhiteBgHover};
					border-color: ${props.theme.btnWhiteBorder};
					color: ${props.theme.primaryDark};
				}
			`;
        }
    }}
    ${(props) => {
        if (props.dark) {
            return `
                color: ${props.theme.white};
				background-color: ${props.theme.primaryDark};
				border-color: ${props.theme.primaryDark};
				:hover {
					background-color: ${props.theme.primaryDark};
					border-color: ${props.theme.primaryDark};
					color: ${props.theme.white};
				}
			`;
        }
    }}
  border-width: 1px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;

    ${(props) => {
        if (props.static) {
            return `
  			background-color: ${props.theme.grayLight};
            border-color: ${props.theme.borderColor};
            color: ${props.theme.gray};
            box-shadow: none;
            pointer-events: none;
  		`;
        }
    }};

    ${(props) => {
        if (props.small) {
            return `
			  border-radius: 2px;
			  font-size: 1.35rem;
			  svg {
			  	width: 14px !important;
			  	height: 14px !important;
			  }
  		`;
        }
    }};

    @media (max-width: 768px) {
        ${(props) => props.responsive && "width: 100%;"}
    }

    svg {
        width: 16px;
        height: 16px;
        &:not(:only-child):first-child {
            margin-right: 5px;
            margin-left: -5px;
        }
        &:not(:only-child):last-child {
            margin-left: 5px;
            margin-right: -5px;
        }
        :only-child {
            margin-right: -5px;
            margin-left: -5px;
        }
    }

    ${(props) => {
        if (props.loading) {
            return `
  			@-webkit-keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				}
				@keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				  to {
				    transform: rotate(359deg);
				  }
				}
				color: transparent !important;
				pointer-events: none;
				&::after {
				  -webkit-animation: spinAround 500ms infinite linear;
				  animation: spinAround 500ms infinite linear;
				  border: 2px solid #dbdbdb;
				  border-radius: 290486px;
				  border-right-color: transparent;
				  border-top-color: transparent;
				  content: "";
				  display: block;
				  height: 1em;
				  position: relative;
				  width: 1em;
				  position: absolute;
				  left: calc(50% - (1em / 2));
				  top: calc(50% - (1em / 2));
				  position: absolute !important;
				}
  		`;
        }
    }};
`;

export const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${(props) => {
            if (props.isCentered) {
                return "center;";
            } else if (props.isRight) {
                return "flex-end;";
            } else {
                return "flex-start;";
            }
        }}
        ${Button}:not (: only-child) {
        margin-bottom: 10px;
    }

    & ${Button} + ${Button} {
        margin-left: 10px;
    }

    ${(props) =>
        props.hasLink
            ? `
                & ${Button} + a {
                    margin-left: 10px;
                }`
            : ""}
`;

export const Icon = styled.span`
    display: inline-block;
    vertical-align: middle;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    &:not(:only-child):first-child {
        margin-right: 5px;
    }
    &:not(:only-child):last-child {
        margin-left: 5px;
    }
    + & {
        margin-left: 10px;
    }
    svg {
        color: ${(props) => props.theme.primaryDark};
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
`;
