import { css, createGlobalStyle } from "styled-components";

// prettier-ignore
export const clobalcssS = css`
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    menu,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section {
        display: block;
    }
    /* HTML5 hidden-attribute fix for newer browsers */
    *[hidden] {
        display: none;
    }
    body {
        line-height: initial;
    }
    menu,
    ol,
    ul {
        list-style: none;
    }
    blockquote,
    q {
        quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: "";
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .main__content {
        padding-top: 106px;
        position: relative;
        display: block;
    }

    body {
        margin: 0;
        padding: 0;
        // font-family: sans-serif;
        // font-family: Lato, sans-serif;
        // font-style: normal;

        // font-family: arial;
        font-family: tahoma, verdana, sans-serif;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: ${(props) => props.theme.primaryDark} !important;
        overflow-x: hidden;
        background-color: ${(props) => props.theme.white};
    }

    html {
        font-size: 10px;
        min-height: 100vh;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        min-width: 300px;
        // overflow-x: hidden;
        // overflow-y: auto;
        text-rendering: optimizeLegibility;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-size-adjust: 100%;
        height: 100%;
        height: -webkit-fill-available;
        @media (max-width: 767px) {
            overflow-x: hidden;
        }
    }

    *:focus {
        outline: none;
    }
    a {
        text-decoration: none;
        color: rgb(81, 116, 223);
    }
    li {
        list-style: none;
    }

    #root {
        height: 100%;
    }

    .fallback {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ReactModal__Body--open {
        overflow: hidden;
        height: 100%;
        /* max-height: 100vh; */
        /* html ,#root { */
        /* position: fixed; */
        overflow: hidden;
        /* } */
    }
    .gray-bg {
        background-color: ${(props) => props.theme.grayLight};
    }

    .is-hidden {
        display: none !important;
    }

    .header-wrapper {
        position: fixed;
        top: 0;
        z-index: 301;
        width: 100%;
    }

    .header-hide {
        .header-wrapper {
            display: none;
        }
    }
    .is-fullwidth {
        width: 100%;
    }

    .header {
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        background-color: ${(props) => props.theme.whiteAndPrimary};
        max-height: 65px;
        height: 65px;
        flex-direction: row;
        min-height: 65px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .header-left {
            width: 100%;
            display: flex;
            padding-right: 100px;
            align-items: center;
        }
        .header-right {
            flex: none;
            padding-right: 10px;
        }
        .header-item {
            display: inline-block;
            vertical-align: middle;
            position: relative;
        }
        .header-item:not(:last-child) {
            margin-right: 20px;
            padding-right: 20px;
            &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 30px;
                background-color: ${(props) =>
                    props.theme.borderColor} !important;
                right: 0;
                top: 50%;
                margin-top: -15px;
            }
            @media (max-width: 980px) {
                margin-right: 10px;
                padding-right: 10px;
            }
        }
        .logo {
            display: inline-block;
            vertical-align: middle;
            fill: ${(props) => props.theme.primaryDarkMode} !important;
            flex: none;
            width: 200px;
            &.mayorea-logo {
                width: 150px;
            }

            margin-right: 10px;
            @media (max-width: 1200px) {
                width: 130px;
            }
            @media (max-width: 361px) {
                width: 80px;
                margin-right: 0;
            }
        }
        .auth-buttons {
            display: inline-block;
            vertical-align: middle;
        }

        .icons {
            display: inline-block;
            vertical-align: middle;
            > li {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                color: ${(props) => props.theme.primaryDark} !important;
                > a {
                    cursor: pointer;
                }
                svg {
                    color: ${(props) => props.theme.primaryDark} !important;
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            > li + li {
                margin-left: 15px;
            }
            .cart-counter {
                text-decoration: none;
                color: ${(props) => props.theme.primaryDark} !important;
                font-family: helvetica;
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                font-weight: bold;
                margin-left: 1px;
            }
        }
        .add {
            display: inline-block;
            vertical-align: middle;

            a {
                color: tomato;
                font-size: 12px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                svg {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 3px;
                    width: 11px;
                    margin-top: -3px;
                }
            }
            &.blue {
                a {
                    color: ${(props) => props.theme.primaryDark} !important;
                }
            }
            &.gray {
                a {
                    color: ${(props) => props.theme.primaryDark} !important;
                    opacity: 0.7;
                }
            }
        }

        @media (max-width: 1200px) {
            .button {
                padding: 0px 10px;
                height: 35px;
                line-height: 33px;
                font-size: 1.2rem;
            }
            .header-left {
                padding-right: 30px;
            }
        }
        @media (max-width: 980px) {
            height: 49px;
            max-height: 49px;
            min-height: 49px;
            & {
                padding: 2px;
            }
            .header-left {
                padding-right: 0;
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 100%;
        margin-top: 10px !important;
        right: 0;
        border-radius: 3px;
        background-color: ${(props) => props.theme.white} !important;
        z-index: 3;
        padding: 5px 0;
        box-shadow: 0 5px 12px -5px rgba(0, 0, 0, 0.5);
        border: solid 1px ${(props) => props.theme.borderColor} !important;
        max-height: calc(100vh - 70px);
        overflow-y: auto;
        &:not(.is-active) {
            display: none;
        }
        li {
            white-space: nowrap;
            cursor: pointer;
            display: block;
            a {
                font-size: 14px;
                display: flex;
                text-decoration: none;
                padding: 10px 15px;
                color: ${(props) => props.theme.gray} !important;
                align-items: center;
                &:hover {
                    color: ${(props) => props.theme.primaryDark} !important;
                }
                span {
                    font-size: 12px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        li + li {
            border-top: solid 1px ${(props) => props.theme.borderColor} !important;
        }
    }

    .language-picker {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        text-align: left;
        background-color: ${(props) => props.theme.whiteAndPrimary};
        margin-right: 0 !important;
        .current {
            cursor: pointer;
            display: block;
            height: 40px;
            padding: 8px;
            img {
                margin-right: 0;
            }
        }
        li svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
        img {
            padding: 0;
            margin: 0;
            width: 25px;
            height: 24px;
            border-radius: 50%;
            margin-right: 5px;
        }
        .dropdown {
            right: 10px;
        }
    }

    .button {
        display: inline-block;
        cursor: pointer;
        padding: 0px 30px;
        font-family: arial;
        height: 40px;
        line-height: 38px;
        font-size: 1.5rem;
        text-align: center;
        white-space: nowrap;
        color: ${(props) => props.theme.primaryDarkMode};
        border-radius: 4px;
        text-decoration: none;
        border: solid 1px ${(props) => props.theme.btnDefaultBorder};
        outline: none;
        & + & {
            margin-left: 10px;
        }
        &:hover {
            border-color: ${(props) => props.theme.gray};
            color: ${(props) => props.theme.primaryDark};
        }
        &.is-primary {
            color: #fff;
            border-color: ${(props) => props.theme.btnPrimaryBorder};
            background-color: ${(props) => props.theme.primary};
            &:hover {
                background-color: ${(props) => props.theme.primaryHover};
                border-color: ${(props) => props.theme.btnPrimaryBorder};
            }
        }
        &.is-white {
            background-color: ${(props) => props.theme.btnWhiteBg};
            border-color: ${(props) => props.theme.btnWhiteBorder};
            color: ${(props) => props.theme.primaryDarkMode};
            :hover {
                background-color: ${(props) => props.theme.btnWhiteBgHover};
                border-color: ${(props) => props.theme.btnWhiteBorder};
                color: ${(props) => props.theme.primaryDarkMode};
            }
        }
    }

    .searchform {
        width: 100%;
        position: relative;
        box-shadow: 0 3px 6px -6px rgba(0, 0, 0, 0.4);
        input {
            width: 100%;
            height: 40px;
            padding: 5px 45px 5px 10px;
            border-radius: 3px;
            font-size: 14px;
            border: solid 1px ${(props) => props.theme.inputBorder} !important;
            background-color: ${(props) => props.theme.inputBg} !important;
            color: ${(props) => props.theme.black} !important;
            &:focus {
                border-color: #e3e3e3;
                @media (min-width: 981px) {
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-color: transparent;
                    & + button {
                        border-bottom-right-radius: 0 !important;
                    }
                }
            }
        }
        button {
            position: absolute;
            top: 0;
            right: 0px;
            padding: 0;
            color: #fff;
            background-color: ${(props) => props.theme.primaryAndWhite};
            height: 100%;
            width: 40px;
            text-align: center;
            border: none;
            border-radius: 0 3px 3px 0;
            cursor: pointer;
            opacity: 1;
            svg {
                width: 18px;
                height: 18px;
                margin-top: 3px;
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .nav-container {
        box-shadow: 0 3px 5px -4px rgba(0, 0, 0, 0.1);
        border-top: solid 1px ${(props) => props.theme.borderColor};
        position: relative;
        background-color: ${(props) => props.theme.whiteAndPrimary};
        display: flex;
        align-items: center;
        position: relative;
        @media (max-width: 980px) {
            padding: 10px;
            --safe-area-inset-left: env(safe-area-inset-left, 10px);
            padding-left: var(--safe-area-inset-left) !important;

            /*padding-left: 60px;*/
        }

        @media (max-width: 980px) ${(props) =>
                !props.theme.isStandalone
                    ? "and (display-mode: standalone)"
                    : ""} {
            --safe-area-inset-top: env(safe-area-inset-top, 10px);
            padding-top: calc(var(--safe-area-inset-top) + 5px) !important;
        }
        > div:not(:last-child) {
            margin-right: 10px;
        }
        .desktop-navicon {
            cursor: pointer;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }
    }
    .header-wrapper {
        nav {
            text-align: center;
            position: relative;
            padding-right: 40px;
            width: 100%;
            a {
                display: block;
                text-decoration: none;
                font-size: 14px;
                border-bottom: solid 1px transparent;
                color: ${(props) => props.theme.primaryDark};
            }
            > ul {
                margin: 0;
                padding: 0;
                white-space: nowrap;
                overflow: auto;
                scrollbar-visibility: hidden;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            > ul > li {
                display: inline-block;
                vertical-align: middle;
                margin-left: -1px;
                &:hover .submenu {
                    display: block !important;
                }
                > a {
                    height: 40px;
                    line-height: 40px;
                    padding: 0px 20px;
                    @media (max-width: 1222px) {
                        padding: 0px 10px;
                    }
                }
                &:hover > a {
                    color: ${(props) => props.theme.primaryDarkMode};
                    border-color: ${(props) => props.theme.primaryDarkMode};
                }
            }

            .submenu {
                display: none;
                z-index: 320;
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                min-height: 400px;
                background-color: ${(props) => props.theme.white};
                box-shadow: 0 7px 5px -4px rgba(0, 0, 0, 0.1);
                padding: 50px;
                text-align: left;
                ul.categories {
                    li {
                        &.is-active {
                            a {
                                font-weight: normal;
                                background-color: ${(props) => props.theme.graySoft} !important;
                                &:after {
                                    content: '→';
                                    flex: none;
                                    margin-top: -2px;
                                    margin-left: 10px;
                                }
                            }
                        }
                        a {
                            padding: 10px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
                li {
                    white-space: nowrap;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                li a {
                    color: ${(props) => props.theme.primaryDark};
                    opacity: 0.7;
                    padding: 5px 10px;
                    border-radius: 3px;
                    position: relative;
                }
                li.is-active a {
                    opacity: 1;
                    font-weight: bold;
                }
                li a:hover {
                    opacity: 1;
                }
                .wrap {
                    display: flex;
                    margin: auto;
                    width: 98%;
                    max-width: 1150px;
                }
                &-right {
                    flex: none;
                    img {
                        width: 200px;
                        height: 200px;
                        object-fit: cover;
                        flex: none;
                    }
                }
                &-left {
                    width: 100%;
                    margin-right: 30px;
                    display: flex;
                }

                .level {
                    flex: none;
                    min-width: 225px;
                    > ul:not(.is-active) {
                        display: none;
                    }
                    &.second-level {
                        ul {
                            margin-top: -8px;
                            margin-bottom: -8px;
                        }
                    }
                }
                .level + .level {
                    margin-left: 30px;
                    padding-left: 20px;
                    border-left: solid 1px ${(props) => props.theme.graySoft} !important;
                }
            }
        }
    }

    .navicon {
        /*display: none;*/
        cursor: pointer;
        /*position: absolute;*/
        /*left: 8px;*/
        /*top: 12px;*/
        width: 35px;
        height: 35px;
        /*padding: 0 0 0 11px;*/

        border: none;
        margin: 0;

        background-color: transparent;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .responsive-menu {
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        box-shadow: 0 7px 5px -4px rgba(0, 0, 0, 0.3);
        display: none;
        z-index: 350;
        overflow: auto;
        height: calc(~"100vh - 110px");
        @media (max-width: 980px) {
            &.is-active {
                display: block;
            }
        }
        li + li {
            border-top: solid 1px #e3e3e3;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            a {
                color: #464646;
                display: block;
                text-decoration: none;
                padding: 13px 10px;
            }
            position: relative;
            &.has-menu:after {
                content: "";
                width: 10px;
                height: 10px;
                border-right: 2px solid #000;
                border-top: 2px solid #000;
                position: absolute;
                right: 15px;
                top: 50%;
                margin-top: -7px;
                transform: rotate(45deg);
                opacity: 0.7;
            }
        }
        .rm-header {
            text-align: center;
            padding: 10px 40px;
            border-top: solid 1px #e3e3e3;
            border-bottom: solid 1px #e3e3e3;
            position: relative;
            h2 {
                font-size: 18px;
                font-weight: bold;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                margin: 0;
                text-overflow: ellipsis;
            }
        }
        .back-btn {
            position: absolute;
            left: 15px;
            top: 3%;
            cursor: pointer;
            left: 14px;
            border: none;
            background: white;
            width: 40px;
            height: 40px;
        }
        .back-btn-span {
            width: 13px;
            height: 13px;
            cursor: pointer;
            border-left: 2px solid #000;
            border-top: 2px solid #000;
            left: 15px;
            position: absolute;
            margin-top: -7px;
            transform: rotate(-45deg);
            opacity: 0.7;
        }

        .close-btn {
            height: 40px;
            width: 41px;
            right: 8px;
            // background: red;
            top: 1px;
            background-color: transparent;
            border: none;
            outline: none;
            position: absolute;
            svg {
                position: absolute;
                top: 50%;
                margin-top: -7px;
                width: 14px;
                right: 13px;
            }
        }
    }
    .clear-button {
        background-color: transparent;
        border: none;
    }

    @media (max-width: 980px) {
        .is-hidden-980 {
            display: none !important;
        }
    }

    @media (max-width: 580px) {
        .is-hidden-580 {
            display: none !important;
        }
    }

    .is-visible-768 {
        display: none;
    }

    .is-visible-mobile {
        display: none;
    }
    @media (max-width: 768px) {
        .is-visible-768 {
            display: block;
        }

        .is-visible-mobile {
            display: block;
        }
        .is-hidden-mobile {
            display: none !important;
        }
    }

    .is-visible-980 {
        display: none;
    }
    @media (max-width: 980px) {
        .is-visible-980 {
            display: block;
        }
    }

    .stand-alone-menu {
        display: none;
    }

    .toggle-icon--bar {
        display: block;
        width: 25px;
        height: 2px;
        background-color: ${(props) => props.theme.primaryDark} !important;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform-origin: 1px;
        -ms-transform-origin: 1px;
        transform-origin: 1px;
        border-radius: 5px;
    }

    .site-header-menu-toggle.active .toggle-icon--bar-top,
    .active .toggle-icon--bar-top {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .site-header-menu-toggle.active .toggle-icon--bar:nth-child(2),
    .active .toggle-icon--bar:nth-child(2) {
        opacity: 0;
    }

    .site-header-menu-toggle.active .toggle-icon--bar-bottom,
    .active .toggle-icon--bar-bottom {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .toggle-icon--bar + .toggle-icon--bar {
        margin-top: 7px;
    }

    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        // .responsive-menu {
        //   .header {
        //     display: none;
        //   }
        // }
        .nav-container {
            padding-left: 10px;
        }
    }

    .message {
        font-size: 12px;
    }

    .stand-alone-menu {
        display: none;
    }
    .reset-body {
        #main-content {
            padding-top: 0 !important;
            @media (max-width: 980px) ${(props) =>
                    !props.theme.isStandalone
                        ? "and (display-mode: standalone)"
                        : ""} {
                --safe-area-inset-top: env(safe-area-inset-top);

                padding-top: 46px !important;
            }
        }
        .button_back-to-top {
            top: 65px;

            @media (max-width: 980px) ${(props) =>
                    !props.theme.isStandalone
                        ? "and (display-mode: standalone)"
                        : ""} {
                --safe-area-inset-top: env(safe-area-inset-top);

                top: calc(var(--safe-area-inset-top) + 50px);
            }
        }
        &.back-top-btn-hidden {
            .button_back-to-top {
                display: none;
            }
        }
    }
    @media (max-width: 1023px) {
        body.ntx-media #root {
            padding-bottom: 0;
            overflow: hidden;
        }
    }

    .show-on-standalone {
        display: none;
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        body {
            overflow-x: hidden;
        }
        #main-content {
            padding-bottom: 100px;
            margin-top: -46px;
        }
        .hide-search-bar-standalone {
            .header-wrapper {
                display: none;
            }
            .main__content.site-main {
                margin-top: -106px !important;
            }
        }
        .stand-alone-footer {
            margin: 0;
        }
        .nav-container {
            border-top: none !important;
            box-shadow: none !important;
        }

        .hide-on-standalone {
            display: none !important;
        }
        .show-on-standalone {
            display: block !important;
        }

        .stand-alone-menu {
            display: block !important;
            position: fixed;
            background-color: white;
            -webkit-box-shadow: 0 -2px 9px -2px rgba(0, 0, 0, 0.2);

            box-shadow: 0 -2px 9px -2px rgba(0, 0, 0, 0.2);
            border-top: 1px solid #d6d6d6;
            bottom: 0px;
            --safe-area-inset-bottom: env(safe-area-inset-bottom);

            padding-bottom: var(--safe-area-inset-bottom);
            left: 0px;
            z-index: 999;
            border-radius: 0px;
            right: 0px;
            background-color: white;
            .hamburger {
                height: 23px;
                position: relative;
                left: 3px;
                .toggle-icon--bar {
                    background-color: #484848;
                }
            }
            ul {
                list-style: none;
                padding: 0 20px;
                display: flex;
                border-radius: 6px;
                align-items: center;
                justify-content: space-between;
                background-color: white;
                margin: 0;
                li {
                    display: inline-block;
                    a {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        margin-top: -1px;
                        border-top: 2px solid transparent;
                        border-top: 0;
                        margin-top: 0;
                        opacity: 0.7;
                        svg {
                            fill: black;
                            color: black;
                            width: 23px;
                            height: 23px;
                            object-fit: contain;
                            opacity: 0.7;
                        }
                        &.selected {
                            /*border-top: 2px solid red;*/
                            border-radius: 0px;
                            fill: black;
                            fill: black;
                            border-top: none;
                            opacity: 1;
                            svg {
                                display: none;
                            }
                        }
                        &:not(.selected) {
                            .selected-icon {
                                display: none;
                            }
                        }
                        .selected-icon {
                            margin-top: -1px;
                            svg {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        #chat-widget-container {
            display: none !important;
        }

        .searchform {
            box-shadow: none !important ;
            button {
                left: 0px;
                background-color: transparent;
                color: #112c55;
                width: 36px;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
            input {
                border-radius: 21px;
                padding: 5px 15px 5px 30px;
            }
        }
    }
    #inline-badge {
        margin: 20px auto;
        display: block;
        & > div {
            margin: 40px auto;
            display: block;
        }
    }
    .concepts-icon {
        .cls-1 {
            fill: #626465;
        }
        .cls-2 {
            fill: #fff;
        }
    }
    .santini-icon {
        .cls-1 {
            fill: #e42629;
        }
        .cls-2 {
            fill: #fff;
        }
    }
    .merletto-icon {
        .cls-1 {
            fill: #fcce0b;
        }
        .cls-2 {
            fill: #e82227;
        }
        .cls-2,
        .cls-3,
        .cls-4 {
            fill-rule: evenodd;
        }
        .cls-3 {
            fill: #9a1e20;
        }
        .cls-4 {
            fill: #fff;
        }
    }
    .diangelo-icon {
        .cls-1 {
            fill: #2d2d2c;
        }
        .cls-2 {
            fill: #fff;
        }
    }

    .loader {
        display: block;
        margin: 40px auto;
        max-width: 150px;
        img {
            max-width: 150px;
        }
    }
    .product-detail {
        @media (max-width: 980px) ${(props) =>
                !props.theme.isStandalone
                    ? "and (display-mode: standalone)"
                    : ""} {
            #main-content {
                padding-top: 60px;
            }
        }
        .standalone-hide-force {
            display: none !important;
        }
    }
    .is-full-height {
        height: 100%;
    }
    .is-bg-light {
        background-color: ${(props) => props.theme.grayLight};
    }
    .fullheight {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .show-on-mobile {
        display: none;
    }
    @media (max-width: 768px) {
        .hide-on-mobile {
            display: none !important;
        }
        .show-on-mobile {
            display: block;
        }
    }

    .page-terms > div {
        .main__content-wrapper {
            height: 100%;
        }
        .main__content {
            padding: 0;
            height: 100%;
        }
    }
    .has-text-centered {
        text-align: center;
    }
    .is-relative {
        position: relative;
    }

    .bg-dark {
        background-color: #141414;
    }
    .link {
        color: ${(props) => props.theme.link};
        cursor: pointer;
    }

    /* Store custom header */
    .store {
        &-exit-btn {
            flex: none;
            position: relative;
            width: 50px;
            height: 50px;
            cursor: pointer;
            background-color: ${(props) => props.theme.grayLight};
            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                content: "";
                display: block;
                background-color: ${(props) => props.theme.primaryDark};
            }
            &:before {
                width: 20px;
                height: 1px;
            }
            &:after {
                width: 1px;
                height: 20px;
            }
        }
        &-logo {
            padding-left: 10px;
            padding-right: 20px;
            img {
                width: auto;
                height: 40px;
                object-fit: contain;
                object-position: center left;
                display: block;
            }
        }

        &-header {
            .nav-container {
                .desktop-navicon,
                .language-picker {
                    display: none;
                }
                nav a {
                    color: ${(props) => props.theme.primaryDark};
                }
            }
        }
        &-loader {
            color: transparent !important;
            pointer-events: none;
            width: 58px;
            height: 40px;
            position: relative;
            @-webkit-keyframes spinAround {
                from {
                    transform: rotate(0deg);
                }
            }
            @keyframes spinAround {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }
            color: transparent !important;
            pointer-events: none;
            &::after {
                -webkit-animation: spinAround 500ms infinite linear;
                animation: spinAround 500ms infinite linear;
                border: 3px solid #dbdbdb;
                border-radius: 290486px;
                border-right-color: transparent;
                border-top-color: transparent;
                content: "";
                display: block;
                height: 1em;
                position: relative;
                width: 1em;
                position: absolute;
                left: calc(50% - (1em / 2));
                top: calc(50% - (1em / 2));
                position: absolute !important;
            }
        }
    }
`;

export const GlobalCss = createGlobalStyle`${clobalcssS}`;

export default clobalcssS;
