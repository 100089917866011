import styled from "styled-components";

export const LoadLayer = styled.div`
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 14px;
        color: ${props => props.theme.gray};
    }
    progress {
        width: 250px;
    }
    svg {
        fill: ${props => props.theme.primaryDarkMode};
    }
`;

export const Progress = styled.div`
    width: 250px;
    height: 7px;
    background-color: ${props => props.theme.borderColor};
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${props => props.theme.primaryDarkMode};
        height: 100%;
        transition: all 200ms linear;
        width: ${(props) => props.value}%;
    }
`;
