import React from "react";

// import MainTemplate from "./js/templates/MainTemplate";
import loadable from "@loadable/component";
import retry from "./componentLoader";

import LoadLayerComponent from "./js/components/LoadLayer";
// import NTXMedia from "./js/page/NTXMedia";
// import ContactUs from "./js/page/ContactUs";
// import PageView from "./js/page/PageView";
// import AccountRecovery from "./js/authentication/AccountRecovery";
// import AccountInvite from "./js/authentication/AccountInvite";
// import Collection from "./js/collection";
// import Products from "./js/products/Products";
// import CartView from "./js/cart/CartView";
// import MyAccount from "./js/account";
// import Login from "./js/authentication";
// import Checkout from "./js/checkout2/Checkout";
// import OrderDetails from "./js/checkout2/OrderDetails";

function FallbackComponent() {
	return (
		<div className="fallback">
			<LoadLayerComponent loadingText="Loading" progress={50} />
		</div>
	);
}

const OrderDetails = loadable(
	() => retry(() => import("./js/checkout2/OrderDetails")),
	{
		fallback: <FallbackComponent />,
	},
);
const Page = loadable(() => retry(() => import("./js/page")), {
	fallback: <FallbackComponent />,
});

const Checkout = loadable(
	() => retry(() => import("./js/checkout2/Checkout")),
	{
		fallback: <FallbackComponent />,
	},
);
const ShippingOptions = loadable(
	() => retry(() => import("./js/checkout2/ShippingOptions")),
	{
		fallback: <FallbackComponent />,
	},
);
const NTXMedia = loadable(() => retry(() => import("./js/page/NTXMedias")), {
	fallback: <FallbackComponent />,
});

const CartView = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/cart2/index")),
	{
		fallback: <FallbackComponent />,
	},
);
const HomeLogin = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/homeLogin2")),
	{
		fallback: <FallbackComponent />,
	},
);
const Collection = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/collection2")),
	{
		fallback: <FallbackComponent />,
	},
);
const Products = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/products2")),
	{
		fallback: <FallbackComponent />,
	},
);
// import Search from "./js/Search";
const Search = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/collection2/search"),
		),
	{
		fallback: <FallbackComponent />,
	},
);
const Login = loadable(
	() =>
		retry(() => import(/* webpackPrefetch: true */ "./js/authentication")),
	{
		fallback: <FallbackComponent />,
	},
);
const TermsValidation = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/authentication/TermsValidation"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);
const ContactUs = loadable(() => retry(() => import("./js/page/ContactUs")), {
	fallback: <FallbackComponent />,
});
const ProductosSugeridos = loadable(
	() => retry(() => import("./js/simpleViews/ProductosSugeridos")),
	{
		fallback: <FallbackComponent />,
	},
);
const ClaimForm = loadable(() => retry(() => import("./js/page/ClaimForm")), {
	fallback: <FallbackComponent />,
});
const ClaimHelp = loadable(() => retry(() => import("./js/page/ClaimHelp")), {
	fallback: <FallbackComponent />,
});
const AccountRecovery = loadable(
	() => retry(() => import("./js/authentication/AccountRecovery")),
	{
		fallback: <FallbackComponent />,
	},
);
const AccountInvite = loadable(
	() => retry(() => import("./js/authentication/AccountInvite")),
	{
		fallback: <FallbackComponent />,
	},
);
const NoMatch = loadable(() => retry(() => import("./js/NoMatch")), {
	fallback: <FallbackComponent />,
});
const BlogPost = loadable(() => retry(() => import("./js/blog/BlogPost")), {
	fallback: <FallbackComponent />,
});
const Blog = loadable(() => retry(() => import("./js/blog/Blog")), {
	fallback: <FallbackComponent />,
});

// const PDT = loadable(() => import("./js/pdt/index.js"))
const AmbientView = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/ambient/index.js"),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const AmbientCollection = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/ambient/AmbientCollection.js"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const MyAccount = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/myaccount/index.js"),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const OrdersView = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/myorders/OrdersWithParentData.js"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const Wishlist = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/wishlist/index.js"),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const WishlistView = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/wishlist/WishlistView.js"),
		),
	{
		fallback: <FallbackComponent />,
	},
);
// const BlackFriday = loadable(
//     () => retry(() => import("./js/simpleViews/blackFriday")),
//     {
//         fallback: <FallbackComponent />,
//     }
// );
const Showroom = loadable(
	() => retry(() => import("./js/simpleViews/showroom")),
	{
		fallback: <FallbackComponent />,
	},
);
const AboutUs = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/simpleViews/history"),
		),
	{
		fallback: <FallbackComponent />,
	},
);
const Help = loadable(
	() =>
		retry(() =>
			import(/* webpackPrefetch: true */ "./js/simpleViews/help"),
		),
	{
		fallback: <FallbackComponent />,
	},
);
const StandAloneMenu = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/simpleViews/standaloneMenu"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);
// const Referral = loadable(
//     () =>
//         retry(() =>
//             import(/* webpackPrefetch: true */ "./js/simpleViews/referral")
//         ),
//     {
//         fallback: <FallbackComponent />,
//     }
// );
const StandAloneUser = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/simpleViews/standaloneUser"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const ReferralBig = loadable(
	() =>
		retry(() =>
			import(
				/* webpackPrefetch: true */ "./js/simpleViews/referralLarge"
			),
		),
	{
		fallback: <FallbackComponent />,
	},
);

const AllDepartments = loadable(
	() =>
		retry(() => import(/* webpackPrefetch: true */ "./js/allDepartments")),
	{
		fallback: <FallbackComponent />,
	},
);
const personalized = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/Personalized")),
	{
		fallback: <FallbackComponent />,
	},
);
const ConsentManager = loadable(
	() => retry(() => import("./js/ConsentManager")),
	{
		fallback: <FallbackComponent />,
	},
);
const VideosForSalesreps = loadable(
	() => retry(() => import("./js/videosForSalesreps")),
	{
		fallback: <FallbackComponent />,
	},
);

const SellerProfile = loadable(
	() => retry(() => import("./js/simpleViews/sellerProfile")),
	{
		fallback: <FallbackComponent />,
	},
);

const WhyUs = loadable(() => retry(() => import("./js/simpleViews/WhyUs")), {
	fallback: <FallbackComponent />,
});

// const Generador = loadable(
//     () => retry(() => import("./js/simpleViews/generadorCatalogo")),
//     {
//         fallback: <FallbackComponent />,
//     }
// );

const Pinterest = loadable(
	() => retry(() => import("./js/simpleViews/Pinterest")),
	{
		fallback: <FallbackComponent />,
	},
);

const Brands = loadable(() => retry(() => import("./js/simpleViews/brands")), {
	fallback: <FallbackComponent />,
});
const BrandCollection = loadable(
	() => retry(() => import("./js/brandCollection")),
	{
		fallback: <FallbackComponent />,
	},
);
// const Brand = loadable(() => retry(() => import("./js/brand")), {
//     fallback: <FallbackComponent />,
// });

const Categorias = loadable(
	() => retry(() => import("./js/simpleViews/Categorias")),
	{
		fallback: <FallbackComponent />,
	},
);

const Landing = loadable(
	() => retry(() => import("./js/simpleViews/Landing")),
	{
		fallback: <FallbackComponent />,
	},
);

const ThankYou = loadable(
	() => retry(() => import("./js/simpleViews/ThankYou")),
	{
		fallback: <FallbackComponent />,
	},
);
const ThankYouWithHeader = loadable(
	() =>
		retry(() => import("./js/simpleViews/ThankYou/ThankYouWithHeader.js")),
	{
		fallback: <FallbackComponent />,
	},
);

const LandingPrivateLabel = loadable(
	() => retry(() => import("./js/simpleViews/LandingPrivateLabel")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaHome = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaNosotros = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Nosotros")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaCategorias = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Categorias")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaCategoria = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Categoria")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaBeneficios = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Beneficios")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaPresencia = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Presencia")),
	{
		fallback: <FallbackComponent />,
	},
);

const MarcaPrivadaRegistrarse = loadable(
	() => retry(() => import("./js/simpleViews/MarcaPrivada/Registrarse")),
	{
		fallback: <FallbackComponent />,
	},
);

const Sustainability = loadable(
	() => retry(() => import("./js/simpleViews/Sustainability")),
	{
		fallback: <FallbackComponent />,
	},
);

const ClaimsView = loadable(
	() => retry(() => import(/* webpackPrefetch: true */ "./js/myclaims")),
	{
		fallback: <FallbackComponent />,
	},
);

const Statement = loadable(() => retry(() => import("./js/Statement")), {
	fallback: <FallbackComponent />,
});

const Unsuscribe = loadable(
	() => retry(() => import("./js/simpleViews/unsuscribe")),
	{
		fallback: <FallbackComponent />,
	},
);

// import NoMatch from "./js/NoMatch"

const routes = [
	/**
	 *
	 *
	 * IMPORTANT NEVER CHANGE THE PAGE LOCATION FROM SECOND IN ARRAT IT WILL RETURN ERROR IN THE SERVER.JS FILE
	 *
	 *
	 * */
	{
		path: "/:lang/:brandSlug?/pages/:slug",
		exact: true,
		name: "Page",
		component: Page,
		hasBackButton: true,
	},
	{
		path: "/:lang/",
		exact: true,
		name: "Home",
		component: Page,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/account/recovery",
		exact: true,
		name: "Account Recovery",
		component: AccountRecovery,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/reset-password/:token",
		exact: true,
		name: "Account Recovery",
		component: AccountRecovery,
		hasBackButton: true,
	},
	{
		path: "/:lang/invite/:token",
		exact: true,
		name: "Account Recovery",
		component: AccountInvite,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/orders/:token?",
		exact: true,
		private: true,
		name: "Orders View",
		component: OrdersView,
		hasBackButton: true,
	},

	{
		path: "/:lang/:brandSlug?/collection/:collection",
		exact: true,
		name: "Collection",
		component: Collection,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/no-tags/products/:product",
		exact: true,
		name: "Products Collection",
		component: Products,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/:defaultTags/products/:product",
		exact: true,
		name: "Products Collection",
		component: Products,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/products/:product",
		exact: true,
		name: "Products Collection",
		component: Products,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/:defaultTags",
		exact: true,
		name: "Collection",
		component: Collection,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/:defaultTags/:globalTags",
		exact: true,
		name: "Collection",
		component: Collection,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/collection/:collection/:defaultTags/:globalTags/products/:product",
		exact: true,
		name: "Products Collection",
		component: Products,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/products/:product",
		exact: true,
		name: "Product",
		component: Products,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/cart",
		exact: true,
		name: "Cart",
		component: CartView,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/account/login",
		exact: true,
		name: "Login",
		component: Login,
		hasBackButton: false,
	},
	{
		path: "/:lang/:brandSlug?/account/check",
		exact: true,
		name: "TermsValidation",
		component: TermsValidation,
	},
	{
		path: "/:lang/:brandSlug?/ntxmedia",
		exact: true,
		private: true,
		name: "NTXMedia",
		component: NTXMedia,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/account/logout",
		exact: true,
		name: "Logout",
		component: Login,
		hasBackButton: false,
	},
	{
		path: "/:lang/:brandSlug?/checkout",
		exact: true,
		name: "Checkout",
		component: Checkout,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/checkout/shipping/",
		exact: true,
		name: "Shipping Options",
		component: ShippingOptions,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/order-confirmed/:token",
		exact: true,
		name: "Checkout",
		component: OrderDetails,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/search",
		exact: true,
		name: "Search",
		component: Search,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/home-login",
		exact: false,
		name: "Home Login",
		component: HomeLogin,
		private: true,
		hasBackButton: false,
	},
	{
		path: "/:lang/:brandSlug?/account",
		exact: false,
		name: "Account",
		component: MyAccount,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/contactanos",
		exact: true,
		name: "Contactanos",
		component: ContactUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/contact-us",
		exact: true,
		name: "Contactanos",
		component: ContactUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/contato",
		exact: true,
		name: "Contactanos",
		component: ContactUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/claim",
		exact: true,
		name: "Reclamos",
		component: ClaimForm,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/claim-help",
		exact: true,
		name: "Ayuda Reclamos",
		component: ClaimHelp,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/blog/:slug",
		exact: true,
		name: "Blog",
		component: BlogPost,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/blog",
		exact: true,
		name: "Blog",
		component: Blog,
		private: false,
		hasBackButton: true,
	},

	// {
	//   path: "/:lang/pdt/:product",
	//   exact: true,
	//   name: "PDT",
	//   component: PDT,
	//   private: true
	// },
	{
		path: "/:lang/ambient/:collection/",
		exact: true,
		name: "Ambiente",
		component: AmbientCollection,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/ambient/:collection/:index",
		exact: true,
		name: "Ambiente",
		component: AmbientView,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/wishlist",
		exact: true,
		name: "Wishlist",
		component: Wishlist,
		private: true,
		hasBackButton: false,
	},
	{
		path: "/:lang/:brandSlug?/wishlist/:token",
		exact: true,
		name: "Wishlist Detail",
		component: WishlistView,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/wishlist/accept/:token",
		exact: true,
		name: "Wishlist Detail",
		component: WishlistView,
		private: true,
		hasBackButton: true,
	},

	{
		path: "/:lang/showroom/",
		exact: true,
		name: "Showroom",
		component: Showroom,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/about-us/",
		exact: true,
		name: "About Us",
		component: AboutUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/nosotros/",
		exact: true,
		name: "Nosotros",
		component: AboutUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/help/",
		exact: true,
		name: "Help",
		component: Help,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/stand-alone/:menuIndex?/",
		exact: true,
		name: "StandAloneMenu",
		component: StandAloneMenu,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/referral/",
		exact: true,
		name: "Referral",
		component: ReferralBig,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/standalonemyaccount/",
		exact: true,
		name: "StandAloneUser",
		component: StandAloneUser,
		private: true,
		hasBackButton: false,
	},

	{
		path: "/:lang/personalized/:type?/:tag?/",
		exact: true,
		name: "Personalized",
		component: personalized,
		private: true,
		hasBackButton: true,
	},
	{
		path: "/:lang/all-departments/",
		exact: true,
		name: "All Departments",
		component: AllDepartments,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/cookie-manager/",
		exact: true,
		name: "ConsentManager",
		component: ConsentManager,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/videos-5585557/",
		exact: true,
		name: "VideosForSalesreps",
		component: VideosForSalesreps,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/u/:slug?/",
		exact: true,
		name: "SellerProfile",
		component: SellerProfile,
		private: false,
		hasBackButton: true,
	},

	{
		path: "/:lang/why-us/",
		exact: true,
		name: "WhyUs",
		component: WhyUs,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/categorias/",
		exact: true,
		name: "Categorias",
		component: Categorias,
		private: false,
		hasBackButton: true,
	},

	// {
	//     path: "/:lang/generar-catalogo/",
	//     exact: true,
	//     name: "Generador",
	//     component: Generador,
	//     private: false,
	//     hasBackButton: true,
	// },

	{
		path: "/:lang/:brandSlug?/image-search/",
		exact: true,
		name: "Pinterest",
		component: Pinterest,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/sign-up-to-sell/",
		exact: true,
		name: "Brands",
		component: Brands,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/brands/:slug?",
		exact: true,
		name: "All Brands",
		component: BrandCollection,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug/brand/",
		exact: true,
		name: "Brand",
		component: Page,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/c/:slug",
		exact: true,
		name: "Landing",
		component: Landing,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/gracias-por-registrarse/:treatment?",
		exact: true,
		name: "Thank you",
		component: ThankYou,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/gracias-por-registrarse-full/:treatment?",
		exact: true,
		name: "Thank you",
		component: ThankYouWithHeader,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada-old",
		exact: true,
		name: "Private lable",
		component: LandingPrivateLabel,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada",
		exact: true,
		name: "Marca privada home",
		component: MarcaPrivadaHome,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/nosotros",
		exact: true,
		name: "Marca privada Nosotros",
		component: MarcaPrivadaNosotros,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/categorias",
		exact: true,
		name: "Marca privada Categorias",
		component: MarcaPrivadaCategorias,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/categorias/:slug",
		exact: true,
		name: "Marca privada detalle categorias",
		component: MarcaPrivadaCategoria,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/beneficios",
		exact: true,
		name: "Marca privada beneficios",
		component: MarcaPrivadaBeneficios,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/presencia",
		exact: true,
		name: "Marca privada presencia",
		component: MarcaPrivadaPresencia,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/marca-privada/registrarse",
		exact: true,
		name: "Marca privada registrarse",
		component: MarcaPrivadaRegistrarse,
		private: false,
		hasBackButton: false,
	},
	{
		path: "/:lang/sostenibilidad",
		exact: true,
		name: "Reporte de sostenibilidad",
		component: Sustainability,
		private: false,
		hasBackButton: false,
	},
	{
		path: "/:lang/replace/:public_key",
		exact: true,
		name: "Productos sugeridos",
		component: ProductosSugeridos,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/:brandSlug?/claims/:claimId?",
		exact: true,
		name: "My claims",
		component: ClaimsView,
		private: false,
		hasBackButton: true,
	},
	{
		path: "/:lang/statement/:publicKey",
		exact: true,
		name: "Statement",
		component: Statement,
	},
	{
		path: "/:lang/unsuscribe/:pub/:s",
		exact: true,
		name: "Unsuscribe",
		component: Unsuscribe,
		private: false,
		hasBackButton: false,
	},
	{
		path: "/:lang/",
		exact: false,
		name: "404",
		component: NoMatch,
		private: false,
		hasBackButton: false,
	},
];

export default routes;

if (typeof window !== "undefined" && window.__REGISTERED_LOGIN_HANDLERS__) {
	try {
		window.__REGISTERED_LOGIN_HANDLERS__.push(() => {
			console.log("registered all routes");
			routes.map((index) => {
				if (index.component && index.component.preload) {
					index.component.preload();
				}
			});
		});
	} catch (err) {}
}
