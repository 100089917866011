import React from "react";
import { config } from "../../config";

const Logo = ({ className }) => {
    if (config.IS_NORITEX) {
        return (
            <svg
                className={`logo-svg ${className}`}
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="209"
                height="45"
                viewBox="0 0 3028.000000 658.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    className="fill-logo"
                    transform="translate(0.000000,658.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                >
                    <path
                        className="an"
                        d="M11730 5129 c-405 -42 -751 -200 -1015 -464 -172 -172 -290 -357
-380 -600 -94 -250 -129 -469 -130 -795 0 -402 60 -692 204 -985 164 -332 391
-561 716 -720 419 -206 997 -241 1464 -89 617 201 1005 695 1118 1424 25 161
25 558 0 720 -136 884 -683 1426 -1526 1510 -120 12 -329 11 -451 -1z m420
-564 c282 -42 508 -182 662 -408 57 -84 132 -247 163 -352 87 -303 93 -728 15
-1035 -72 -279 -226 -518 -420 -650 -248 -168 -588 -221 -904 -140 -385 98
-652 416 -748 893 -21 101 -23 140 -23 392 0 268 2 286 28 409 88 408 301 696
612 825 176 74 405 98 615 66z"
                    />
                    <path
                        d="M1454 5051 c-58 -35 -59 -45 -59 -501 0 -378 2 -418 18 -447 36 -67
-47 -63 1267 -63 1297 0 1238 3 1277 -56 17 -27 18 -88 23 -1239 5 -1167 6
-1211 24 -1238 39 -57 38 -57 493 -57 468 0 471 0 505 70 17 33 18 137 18
1738 0 1874 4 1751 -62 1792 -33 20 -45 20 -1753 20 -1665 0 -1721 -1 -1751
-19z"
                    />
                    <path
                        className="an"
                        d="M6330 3260 l0 -1810 330 0 330 0 0 968 c0 873 -6 1229 -26 1586 -4
80 -3 117 4 115 6 -2 63 -92 128 -199 138 -229 267 -431 372 -585 75 -109
1263 -1790 1309 -1852 l24 -33 350 0 349 0 0 1810 0 1810 -336 0 -336 0 5
-1052 c3 -585 10 -1153 17 -1278 6 -124 10 -252 8 -284 l-3 -60 -120 200
c-212 353 -328 522 -1150 1684 -115 162 -287 406 -384 543 l-174 247 -349 0
-348 0 0 -1810z"
                    />
                    <path
                        className="an"
                        d="M14430 3261 l0 -1811 335 0 335 0 0 700 0 700 228 0 227 0 212 -303
c117 -166 307 -437 423 -602 116 -165 242 -344 278 -397 l67 -97 398 -1 c316
0 397 3 391 13 -9 14 -97 137 -239 332 -539 741 -818 1131 -819 1143 -1 9 30
24 84 43 47 15 139 55 205 88 217 109 362 251 456 446 58 121 87 228 97 362
44 600 -265 999 -880 1132 -235 51 -287 54 -1065 58 l-733 4 0 -1810z m1500
1235 c350 -57 500 -208 500 -501 0 -259 -124 -428 -377 -516 -137 -47 -258
-60 -615 -66 l-338 -5 0 557 0 558 368 -6 c261 -4 394 -10 462 -21z"
                    />
                    <path
                        className="an"
                        d="M17920 3260 l0 -1810 330 0 330 0 0 1810 0 1810 -330 0 -330 0 0
-1810z"
                    />
                    <path
                        className="an"
                        d="M19090 4795 l0 -275 585 0 585 0 0 -1535 0 -1535 335 0 335 0 0 1535
0 1535 580 0 580 0 0 275 0 275 -1500 0 -1500 0 0 -275z"
                    />
                    <path
                        className="an"
                        d="M22610 3260 l0 -1810 1220 0 1220 0 0 275 0 275 -890 0 -890 0 0 515
0 515 725 0 725 0 0 275 0 275 -725 0 -725 0 0 470 0 470 845 0 845 0 0 275 0
275 -1175 0 -1175 0 0 -1810z"
                    />
                    <path
                        className="an"
                        d="M25440 5066 c0 -4 224 -322 830 -1176 91 -129 224 -316 293 -414 70
-99 126 -185 123 -191 -3 -9 -1078 -1517 -1273 -1788 l-35 -47 378 2 378 3 47
66 c108 152 655 938 773 1112 70 103 130 187 134 187 4 -1 45 -58 92 -128 80
-120 303 -442 689 -994 l173 -248 375 0 375 0 -18 27 c-10 16 -305 430 -656
921 l-638 893 118 167 c65 92 146 205 179 252 33 47 253 357 488 690 236 333
439 620 453 638 l24 32 -383 0 -384 0 -195 -282 c-107 -155 -256 -371 -330
-479 -75 -108 -185 -271 -245 -362 -61 -92 -113 -166 -117 -164 -4 1 -74 101
-155 222 -82 121 -280 410 -442 643 l-293 422 -379 0 c-208 0 -379 -2 -379 -4z"
                    />
                    <path
                        d="M1471 3763 c-19 -10 -44 -31 -55 -46 -20 -28 -21 -39 -21 -456 0
-391 1 -429 18 -454 42 -64 15 -62 632 -67 522 -5 563 -6 589 -23 56 -37 56
-38 56 -629 0 -521 1 -544 20 -575 39 -64 34 -64 511 -61 l431 3 34 37 34 38
0 1077 c0 836 -3 1083 -13 1102 -6 13 -25 34 -40 45 -28 21 -30 21 -1095 24
-1001 2 -1069 1 -1101 -15z"
                    />
                    <path
                        d="M1435 2446 c-18 -19 -35 -49 -39 -66 -10 -53 -7 -791 4 -831 5 -19
24 -48 41 -65 l30 -29 432 -3 c419 -2 433 -2 465 18 62 38 63 45 60 516 l-3
426 -37 34 -38 34 -441 0 -442 0 -32 -34z"
                    />
                </g>
            </svg>
        );
    } else {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 909.73 201.45"
                className={`logo-svg ${className} mayorea-logo`}
            >
                <defs>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: ".cls-1{isolation:isolate;}.cls-2,.cls-3{fill:#002b54;}.cls-3{mix-blend-mode:darken;}.cls-4,.cls-5{mix-blend-mode:multiply;}.cls-4{fill:url(#linear-gradient);}.cls-5{fill:url(#linear-gradient-2);}",
                        }}
                    />
                    <linearGradient
                        id="linear-gradient"
                        x1="133.59"
                        y1="104.33"
                        x2="100.6"
                        y2="291.43"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0} stopColor="#414042" />
                        <stop
                            offset="0.7"
                            stopColor="#414042"
                            stopOpacity={0}
                        />
                    </linearGradient>
                    <linearGradient
                        id="linear-gradient-2"
                        x1="213.56"
                        y1="145.67"
                        x2="307.06"
                        y2="261.14"
                        xlinkHref="#linear-gradient"
                    />
                </defs>
                <g className="cls-1">
                    <g id="Layer_1" data-name="Layer 1">
                        <path
                            className="cls-2"
                            d="M383.28,192H338l-7.26,21.43H299.81L343.7,92.14h34.22l43.9,121.32H390.54ZM360.64,124.8l-14.86,44.42h29.9Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M425.62,92.14h33.53L482.65,143,506,92.14h33.18l-42,81.23v40.09H467.62V173.37Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M608.47,214.67c-34.39,0-62.39-25.75-62.39-62.22s28-62,62.39-62c34.56,0,62,25.58,62,62S642.86,214.67,608.47,214.67Zm0-27c19.35,0,32-14,32-35.26,0-21.6-12.62-35.25-32-35.25-19.7,0-32.15,13.65-32.15,35.25C576.32,173.71,588.77,187.71,608.47,187.71Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M736.53,92.14c29.73,0,44.59,17.11,44.59,38.19,0,15.21-8.3,29.56-27,35.09l28,48H748.8l-25.23-45.8h-7.09v45.8H686.93V92.14Zm-1.73,24.54H716.48v30.07H734.8c11.06,0,16.25-5.7,16.25-15.21C751.05,122.56,745.86,116.68,734.8,116.68Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M873.92,115.82H829.16v24.54h39.58v22.81H829.16v26.61h44.76v23.68H799.61V92.14h74.31Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M968.63,192H923.35l-7.26,21.43H885.15l43.9-121.32h34.22l43.9,121.32H975.89ZM946,124.8l-14.87,44.42H961Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M326.18,276.16H307.8l-3.52,9.6H300l14.74-39.87h4.56L334,285.76H329.7ZM325,272.81,317,251l-8,21.84Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M398.41,285.76l-10-16.88H381v16.88h-4v-40.4h12.14q6.75,0,10.25,3.24a11,11,0,0,1,3.5,8.5,11.52,11.52,0,0,1-2.51,7.48,12,12,0,0,1-7.43,4l10.4,17.17ZM381,265.59h8.14q4.74,0,7.14-2.31a8.18,8.18,0,0,0,2.4-6.18,8,8,0,0,0-2.31-6.16c-1.54-1.44-4-2.17-7.28-2.17H381Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M468.85,285.76l-17.51-18.67v18.67h-4v-40.4h4v18.9l17.51-18.9H474L455.1,265.53l19.06,20.23Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M520.62,248.66v15.08h15.32v3.35H520.62v15.32h17v3.35H516.58V245.31h21.09v3.35Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M605.74,245.36v3.36H594.53v37h-4v-37H579.22v-3.36Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M723.33,265.36q-3.41,3.18-10.23,3.18H705v17.22h-4.05v-40.4H713.1q6.76,0,10.2,3.18a12,12,0,0,1,0,16.82Zm-.75-8.38q0-4.05-2.25-6.13c-1.5-1.38-3.91-2.08-7.23-2.08H705v16.3h8.14Q722.58,265.07,722.58,257Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M774,282.46h14.5v3.3H770v-40.4h4Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M854.16,276.16H835.78l-3.52,9.6H828l14.74-39.87h4.56L862,285.76h-4.28Zm-1.22-3.35L845,251l-8,21.84Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M905.64,254.87a19,19,0,0,1,7.11-7.34,19.64,19.64,0,0,1,10.06-2.63,20.42,20.42,0,0,1,11.58,3.21,18.18,18.18,0,0,1,7,9h-4.73a13.93,13.93,0,0,0-5.29-6.41,16.78,16.78,0,0,0-16.59-.23,14.4,14.4,0,0,0-5.57,6,21.19,21.19,0,0,0,0,18.06,14.5,14.5,0,0,0,5.57,5.95,16.88,16.88,0,0,0,16.59-.2,13.83,13.83,0,0,0,5.29-6.33h4.73a18,18,0,0,1-7,9,20.65,20.65,0,0,1-11.55,3.17,19.55,19.55,0,0,1-10.06-2.63,18.93,18.93,0,0,1-7.11-7.31,23.09,23.09,0,0,1,0-21.29Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M990,248.66v15.08h15.32v3.35H990v15.32h17v3.35H985.91V245.31H1007v3.35Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M103.32,90.73H137a5.87,5.87,0,0,1,5.31,3.37l37.78,80.33a5.86,5.86,0,0,0,10.62,0l37.52-80.31a5.86,5.86,0,0,1,5.31-3.38h33.09a5.87,5.87,0,0,1,5.21,8.57L206.62,225a6,6,0,0,0-.66,2.71v58a5.87,5.87,0,0,1-5.87,5.87h-30a5.87,5.87,0,0,1-5.87-5.87v-58a6,6,0,0,0-.66-2.71L98.11,99.31A5.87,5.87,0,0,1,103.32,90.73Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-3"
                            d="M236.26,291.56H267.8a4.76,4.76,0,0,0,4.77-4.77V97.88L232,175.49a4.72,4.72,0,0,0-.54,2.21V286.79A4.76,4.76,0,0,0,236.26,291.56Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-2"
                            d="M133.8,291.8H102.25a4.18,4.18,0,0,1-4.77-4.33V98.56L138,176.17a4.73,4.73,0,0,1,.55,2.21V287.47A4.18,4.18,0,0,1,133.8,291.8Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-4"
                            d="M133.8,291.65H102.25a4.78,4.78,0,0,1-4.77-4.77V98L138,175.57a4.76,4.76,0,0,1,.55,2.21v109.1A4.78,4.78,0,0,1,133.8,291.65Z"
                            transform="translate(-97.44 -90.41)"
                        />
                        <path
                            className="cls-5"
                            d="M236.26,291.56H267.8a4.76,4.76,0,0,0,4.77-4.77V97.88L232,175.49a4.72,4.72,0,0,0-.54,2.21V286.79A4.76,4.76,0,0,0,236.26,291.56Z"
                            transform="translate(-97.44 -90.41)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
};

export default React.memo(Logo);
